<template>
  <div class="row ml-2">
    <div class="col-md-6">

      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Picture Evidence Display (Off Dock)</h3>
        </div>

<!--{{form.driver_face}}-->
        <form>
          <div class="card-body">
            <div class="form-group">
              <label for="">Chassis Number</label>
              <input
                  type="text"
                  name="chassis"
                  v-model="form.chassis"
                  class="form-control"
                  id=""
                  placeholder="Enter Chassis Number">
            </div>

            <div class="form-group">
              <label for="">Picture 1: Drivers Face</label>
              <div class="input-group">
                <div class="custom-file">
                  <input
                      type="file"
                      class="custom-file-input"

                      name="driver_face"
                      @change="updateDriverFace"
                      accept="image/*" capture>


                  <label class="custom-file-label" for="">{{form.driver_face ? "driver_face.jpg": "Capture New"}}</label>
                </div>
                <div class="input-group-append">
                  <span class="input-group-text"> <i class="nav-icon fas fa-camera white"></i></span>

                </div>
              </div>

<!--              <input name="driver_face" type="file" @change="updateDriverFace" accept="image/*" capture class="form-input">-->
            </div>

            <div class="form-group">
              <label for="">Picture 2: Dashboard</label>
              <div class="input-group">
                <div class="custom-file">
                  <input type="file"
                         class="custom-file-input"
                         name="dashboard"
                         accept="image/*" capture @change="updateDashboard"


                         id="">
                  <label class="custom-file-label" for="">{{form.dashboard ? "dashboard.jpg":"Capture New"}}</label>
                </div>
                <div class="input-group-append">
                  <span class="input-group-text"> <i class="nav-icon fas fa-camera white"></i></span>

                </div>
              </div>

            </div>


            <div class="form-group">
              <label for="exampleInputFile">Picture 3: Key</label>
              <div class="input-group">
                <div class="custom-file">
                  <input type="file" @change="updateVkey"

                         class="custom-file-input" accept="image/*" capture id="">
                  <label class="custom-file-label" for="">{{ form.vkey ? "key.jpg":"Capture New" }}</label>
                </div>
                <div class="input-group-append">
                  <span class="input-group-text"> <i class="nav-icon fas fa-camera white"></i></span>

                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputFile">Picture 4: vehicle Front View</label>
              <div class="input-group">
                <div class="custom-file">
                  <input type="file" @change="updatefrontview"

                         class="custom-file-input" accept="image/*" capture id="">
                  <label class="custom-file-label" for="">{{ form.frontview? "frontview.jpg":"Capture New" }}</label>
                </div>
                <div class="input-group-append">
                  <span class="input-group-text"> <i class="nav-icon fas fa-camera white"></i></span>

                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputFile">Picture 5: Vehicle Back View</label>
              <div class="input-group">
                <div class="custom-file">
                  <input type="file" class="custom-file-input"
                         accept="image/*" capture @change="updatebackview"

                                                  id="">
                  <label class="custom-file-label" for="">{{form.backview ?"backview.jpg" :"Capture New"}}</label>
                </div>
                <div class="input-group-append">
                  <span class="input-group-text"> <i class="nav-icon fas fa-camera white"></i></span>

                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputFile">Picture 5: Chassis</label>
              <div class="input-group">
                <div class="custom-file">
                  <input type="file" class="custom-file-input"
                         accept="image/*" capture @change="updatechasis"
                         id="">

                  <label class="custom-file-label" for="">{{ form.chassis_capture ? "Chassis.jpg":"Capture New" }}</label>
                </div>
                <div class="input-group-append">
                  <span class="input-group-text"> <i class="nav-icon fas fa-camera white"></i></span>


                </div>
              </div>
            </div>

          </div>

          <div class="card-footer">
            <button :disabled="(form.chassis == '' || form.driver_face == '') "  @click.prevent="submitped" class="btn btn-primary">Submit</button>
            <button  @click.prevent="clear" class="btn btn-primary">Reset</button>

          </div>
        </form>
      </div>



    </div>

  </div>
</template>

<script>
export default {
  mounted() {
    console.log("Component mounted.");
  },

  data() {
    return {

      form: new Form({
        driver_face : "",
        chassis:"",
        dashboard:"",
        // keys:"",
        vkey:'',
        backview:"",
        frontview:"",
        chassis_capture:"",

      }),
    };
  },

  methods: {
    clear() {

      this.form.driver_face = "";
      this.form.chassis = "";
      this.form.dashboard = "";
      this.form.vkey = "";
      this.form.backview = "";
      this.form.frontview = "";
      this.form.chassis_capture = "";

    },

    submitped(){
      // let config = { headers: { 'Content-Type': 'multipart/form-data' } }
      this.$Progress.start();
           this.form.post("api/pedoffdock")
          .then((response)=>{

            Toast.fire({
              icon: "success",
              title: response.data.message,
            });

           this.clear();
            this.$Progress.finish();
          }).catch(()=>{
             Toast.fire({
               icon: "success",
               title: 'Error Occur PED not Saved',
             });
             this.$Progress.fail();
           });


    },
    // GeneratetrackingNumber() {
    //   // const num = Math.floor(Math.random() * (1000 - 1 + 1)) + 1;
    //   // // return (this.form.gi_carrier_ref_no = "BHL" + num + "-CARGO");
    //   const a = Math.round(Date.now() / 1000); // 1405792937;
    //   return (this.form.gi_carrier_ref_no = a);
    // },

    // update1(e){
    //   // console.log('uploading');
    //   let file = e.target.files[0];
    //   let reader = new FileReader();
    //
    //   reader.onloadend =  (file)=>{
    //     console.log('RESULT', reader.result)
    //
    //     this.form.driver_face = reader.result;
    //   }
    //   reader.readAsDataURL(file);
    // },
    handleFileUpload(event, propertyName) {
      let file = event.target.files[0];
      let reader = new FileReader();

      reader.onloadend = () => {
        console.log('RESULT', reader.result);
        this.form[propertyName] = reader.result;
      };

      reader.readAsDataURL(file);
    },
    updateDriverFace(e) {
      this.handleFileUpload(e, 'driver_face');
    },

    updateDashboard(e) {
      // this.handleFileUpload(e, 'dashboard');
      this.handleFileUpload(e, 'dashboard');
    },
    // updateKeys(e) {
    //   this.handleFileUpload(e, 'keys');
    // },

    updateVkey(e){
      this.handleFileUpload(e, "vkey")
    },
    updatebackview(e){
      this.handleFileUpload(e, "backview")
    },
    updatefrontview(e){
     this.handleFileUpload(e, "frontview")
    },

    updatechasis(e){
      this.handleFileUpload(e, "chassis_capture")
    },
  },
  created() {

  }
};
</script>
