<template>
  <div class="row ml-2">
    <div class="col-md-6">

      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Picture Evidence Display (Container)</h3>
        </div>

<!--{{form.driver_face}}-->
        <form>
          <div class="card-body">
            <div class="form-group">
              <label class="required-field" for="">Container Number</label>
              <input
                  type="text"
                  name="chassis"
                  v-model="form.container_number"
                  class="form-control"
                  id=""
                  placeholder="Enter Chassis Number">
            </div>

            <div class="form-group">
              <label class="required-field" for="">Damage Pic 1</label>
              <div class="input-group">
                <div class="custom-file">
                  <input
                      type="file"
                      class="custom-file-input"

                      name="damage_pic1"
                      @change="updateDamagePic1"
                      accept="image/*" capture>


                  <label class="custom-file-label" for="">{{form.damage_pic1 ? "damage_pic1.jpg": "Capture New"}}</label>
                </div>
                <div class="input-group-append">
                  <span class="input-group-text"> <i class="nav-icon fas fa-camera white"></i></span>

                </div>
              </div>

<!--              <input name="driver_face" type="file" @change="updateDriverFace" accept="image/*" capture class="form-input">-->
            </div>

            <div class="form-group">
              <label for="">Damage Pic 2</label>
              <div class="input-group">
                <div class="custom-file">
                  <input type="file"
                         class="custom-file-input"
                         name="damage_pic2"
                         accept="image/*" capture @change="updateDamagePic2"


                         id="">
                  <label class="custom-file-label" for="">{{form.damage_pic2 ? "damage_pic2.jpg":"Capture New"}}</label>
                </div>
                <div class="input-group-append">
                  <span class="input-group-text"> <i class="nav-icon fas fa-camera white"></i></span>

                </div>
              </div>

            </div>

            <div class="form-group">
              <label for="exampleInputFile">Damage Pic 3</label>
              <div class="input-group">
                <div class="custom-file">
                  <input type="file" @change="updateDamagepic3"

                         class="custom-file-input" accept="image/*" capture id="">
                  <label class="custom-file-label" for="">{{ form.damage_pic3 ? "damage_pic4.jpg":"Capture New" }}</label>
                </div>
                <div class="input-group-append">
                  <span class="input-group-text"> <i class="nav-icon fas fa-camera white"></i></span>

                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputFile">Damage Pic 4</label>
              <div class="input-group">
                <div class="custom-file">
                  <input type="file" class="custom-file-input"
                         accept="image/*" capture @change="updateDamagePic4"

                                                  id="">
                  <label class="custom-file-label" for="">{{form.damage_pic4 ?"damage_pic4.jpg" :"Capture New"}}</label>
                </div>
                <div class="input-group-append">
                  <span class="input-group-text"> <i class="nav-icon fas fa-camera white"></i></span>

                </div>
              </div>
            </div>



            <div class="form-group">
              <label>Activity</label>
              <select
                  name="activity"
                  v-model="form.activity"
                  id="activity"
                  class="form-control"
                  :class="{ 'is-invalid': form.errors.has('activity') }"
              >
                <option value="">Select Activities</option>
                <option value="Discharged">Discharged</option>
                <option value="Loading">Loading</option>
              </select>
              <has-error :form="form" field="activity"></has-error>
            </div>

            <div class="form-group">
              <label class="required-field">Container Movement</label>
              <select
                  name="container_movement"
                  v-model="form.container_movement"
                  id="container_movement"
                  class="form-control"
                  :class="{ 'is-invalid': form.errors.has('container_movement') }"
              >
                <option value="">Select Container Movement</option>
                <option value="Gate In">Gate In</option>
                <option value="Gate Out">Gate Out</option>
              </select>
              <has-error :form="form" field="container_movement"></has-error>
            </div>

            <div class="form-group">
              <label>Barging Activity</label>
              <select
                  name="barging_activity"
                  v-model="form.barging_activity"
                  id="barging_activity"
                  class="form-control"
                  :class="{ 'is-invalid': form.errors.has('barging_activity') }"
              >
                <option value="">Select Barging Activities</option>
                <option value="Barge In">Barge In</option>
                <option value="Barge Out">Barge Out</option>
              </select>
              <has-error :form="form" field="barging_activity"></has-error>
            </div>

            <div class="form-group">
              <label class="required-field" for="">Remarks</label>
              <input
                  type="text"
                  name="remarks"
                  v-model="form.remarks"
                  class="form-control"
                  id=""
                  placeholder="Remarks">
            </div>

          </div>

          <div class="card-footer">
            <button :disabled="(form.container_number == '' || form.damage_pic1 == '' || form.container_movement == '' || form.remarks == '') "  @click.prevent="submitped" class="btn btn-primary">Submit</button>
            <button  @click.prevent="clear" class="btn btn-primary">Reset</button>

          </div>
        </form>
      </div>



    </div>

  </div>
</template>

<script>
export default {
  mounted() {
    console.log("Component mounted.");
  },

  data() {
    return {

      form: new Form({
        container_number : "",
        damage_pic1:"",
        damage_pic2:"",
        damage_pic3:'',
        damage_pic4:"",
        activity:"",
        container_movement:"",
        barging_activity:"",
        remarks:"",

      }),
    };
  },

  methods: {
    clear() {

      this.form.container_number = "";
      this.form.damage_pic1 = "";
      this.form.damage_pic2 = "";
      this.form.damage_pic3 = "";
      this.form.damage_pic4 = "";
      this.form.activity = "";
      this.form.container_movement = "";
      this.form.barging_activity = "";
      this.form.remarks = "";

    },

    submitped(){
      // let config = { headers: { 'Content-Type': 'multipart/form-data' } }
      this.$Progress.start();
           this.form.post("api/pedcontainer")
          .then((response)=>{

            Toast.fire({
              icon: "success",
              title: response.data.message,
            });

           this.clear();
            this.$Progress.finish();
          }).catch(()=>{
             Toast.fire({
               icon: "success",
               title: 'Error Occur PED not Saved',
             });
             this.$Progress.fail();
           });


    },

    handleFileUpload(event, propertyName) {
      let file = event.target.files[0];
      let reader = new FileReader();

      reader.onloadend = () => {
        console.log('RESULT', reader.result);
        this.form[propertyName] = reader.result;
      };

      reader.readAsDataURL(file);
    },
    updateDamagePic1(e) {
      this.handleFileUpload(e, 'damage_pic1');
    },

    updateDamagePic2(e) {
      // this.handleFileUpload(e, 'dashboard');
      this.handleFileUpload(e, 'damage_pic2');
    },
    // updateKeys(e) {
    //   this.handleFileUpload(e, 'keys');
    // },

    updateDamagepic3(e){
      this.handleFileUpload(e, "damage_pic3")
    },
    updateDamagePic4(e){
      this.handleFileUpload(e, "damage_pic4")
    },

  },
  created() {

  }
};
</script>

  <style scoped>
  .required-field::after {
    content: " *";
    color: red;
  }
  </style>
