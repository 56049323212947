<template>
<section class="dropdown-wrapper ml-4">
  <div @click="isVisible = !isVisible" class="selected-item">
    <span v-if="selectedItem">{{ selectedItem }}</span>
    <span v-else>Select Item</span>

    <svg
        :class="isVisible ? 'dropdown': ''"
        width="24"
        height="24"
        class="drop-down-icon "
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
      <path d="M12.9999 7.82843V20H10.9999V7.82843L5.63589 13.1924L4.22168 11.7782L11.9999 4L19.778 11.7782L18.3638 13.1924L12.9999 7.82843Z"></path></svg>
</div>
    <div :class="isVisible ? 'visible':'invisible'" class="dropdown-popover">
      <input   class="mb-2 ml-2"  autofocus v-model="searchQuery" placeholder="Search" type="text">
      <span v-if="filteredUser == 0"> No Data Available</span>
      <div class="options">
        <ul>
<!--          <li v-for="(ped, index) in peds.data" :key="`ped-${index}`">-->
            <li @click="selectItem(ped)" v-for="(ped, index) in filteredUser" :key="`ped-${index}`">
<!--            <li v-for="ped in peds.data" :key="ped.id">-->
            {{ ped.chassis }}
          </li>
        </ul>

      </div>
    </div>

<!--  </div>-->

</section>
</template>

<style scoped lang="scss">
.dropdown-wrapper{
  max-width: 350px;
  position: relative;
  margin: 0 auto;
}

.selected-item{
  height: 40px;
  border: 2px solid lightgray;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 400;

  .drop-down-icon{
    transform: rotate(0deg);
    transition: all 0.4s ease;
    &.dropdown{
      transform: rotate(180deg);
    }
  }
}
.dropdown-popover{
  position: absolute;
  border: 2px solid lightgray;
  top: 46px;
  left: 0;
  right: 0;
  background-color: #fff;
  max-width: 100%;
  padding: 10px;
  visibility: hidden;
  transition: all 0.5s linear;
  max-height:0px;
  overflow:hidden;

  &.visible{
    max-height: 450px;
    visibility: visible;
  }

  input{
    width: 90%;
    height: 30px;
    border: 2px solid lightgray;
    font-size: 16px;
    padding-left: 8px;
  }
}

 .options{
   width: 100%;

   ul{
     list-style: none;
     text-align:left;
     padding-left:8px;
     max-height:180px;
     overflow-y: scroll;

     li{
       width: 100%;
       border-bottom: 1px solid lightgray;
       padding: 10px;
        background-color: #f1f1f1;
       cursor: pointer;
       font-size: 16px;
       &:hover{
         background-color: #70878a;
         color:#fff;
         font-weight: bold;
       }

     }
        }
 }


</style>

<script>
import axios from "axios";

export default {

  data() {
    return {
      searchQuery:"",
      selectedItem: null,
      isVisible: false,
     peds: [],
    };
  },

  computed:{
    filteredUser(){
      const query = this.searchQuery.toLowerCase();
      if (this.searchQuery === ""){
        return this.peds.data;
      }
      return this.peds.data.filter((peds)=>{
        return Object.values(peds).some ((word) =>
            String(word).toLowerCase().includes(query));
      });
    }
  },
  mounted() {
    // console.log("Component mounted.");

    axios.get("api/peds")
        .then(({ data }) => (this.peds = data.data));

  },

  methods: {
    selectItem(ped){
      this.selectedItem =  ped.chassis;
      this.isVisible = false;
    }
  },
  created() {

  }
};
</script>
