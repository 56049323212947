<!-- resources/assets/js/components/Gallery.vue -->

<template>
  <div>
    <h1>Image Gallery</h1>
    <div v-for="imageName in imageFileNames" :key="imageName">
      <img :src="getImageUrl(imageName)" :alt="imageName" width="150">
      <p>{{ imageName }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageFileNames: [],
    };
  },
  mounted() {
    // Fetch the list of image file names when the component is mounted
    this.fetchImageFileNames();
  },
  methods: {
    async fetchImageFileNames() {
      try {
        // Assuming you have an API endpoint that returns the list of image names
        const response = await fetch('/api/images');
        const data = await response.json();

        // Update the component's data with the list of image file names
        this.imageFileNames = data.imageFileNames;
      } catch (error) {
        console.error('Error fetching image file names', error);
      }
    },
    getImageUrl(imageName) {
      // Assuming your images are stored in the public directory
      return `/images/${imageName}`;
    },
  },
};
</script>
