<template>
  <div>
<!--    <div class="custom-bg sticky" style="z-index: 1">-->
<!--      <div class="d-flx row">-->
<!--        <div class="row ml-2">-->
<!--          <h2 class="pt-2 ml-2">PED</h2>-->
<!--        </div>-->

<!--      </div>-->
<!--    </div>-->
    <section class="content mt-3">

      <div class="container-fluid">
      <h3>Technical Documentation for Picture Evidence Display</h3>
        <p>Is a system use in managing and displaying picture evidence related to the vehicles being transported through the 5star Logistics terminal.</p>
        <p>This evidence may include photographs or images of the vehicles, their condition, or other relevant visual information. </p>
        <h4>Usage</h4>
        <li>
          <ol></ol>
          <ol>When vehicles are brought into the terminal for shipping or storage, they are often photographed.</ol>
        </li>
      </div>
  </section>
  </div>
</template>

<script>
import axios from "axios";
export default {




};
</script>

<style scoped>

.modal-image{
  width: 150px;
  height: 80px;
}
.form-control {
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 1.1rem;
}
.fai {
  margin-top: 12px;
  cursor: pointer;
  color: blue;
}

.fai:hover {
  color: rgb(97, 97, 176);
}
.d-flx {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.custom-bg {
  background-color: #e8f9fc !important;
  height: 80px;
  border-top: 1px solid #e0eaee;
  border-bottom: 1px solid #e0eaee;
  padding: 10px 20px;
  width: 100%;
}


.scrollable-div {
  /* Set a fixed height for the div */
  height: 500px; /* Adjust the height to your preference */

  /* Enable vertical scrolling when the content overflows the fixed height */
  overflow-y: scroll;

  /* Optional: Add padding or other styles to the div */
  padding: 10px;
  border: 1px solid #ccc;
}

div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.btn {
  height: 40px;
}
</style>