export default [
    { path: '/', component: require('./components/Home.vue').default },
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/scrap-overtime', component: require('./components/Dashboardscrap-overtime.vue').default },
    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '/pedcapture', component: require('./components/Pedcapture.vue').default },
    { path: '/pedcapture-offdock', component: require('./components/Pedcapture-offdock.vue').default },
    { path: '/pedcapture-scrapovertime', component: require('./components/Pedcapture-scrapovertime.vue').default },
    { path: '/pedcapture-container', component: require('./components/Pedcapture-container.vue').default },
    { path: '/pedupload', component: require('./components/Pedupload.vue').default },
    { path: '/dropdown', component: require('./components/Dropdown.vue').default },
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/dashboard-container', component: require('./components/Dashboardcontainer.vue').default },
    { path: '/dashboard-offdock', component: require('./components/Dashboardoffdock.vue').default },

    //On premise
    { path: '/dashboard-onpremise', component: require('./components/ped-onpremise/Dashboard.vue').default },
    { path: '/dashboard-container-onpremise', component: require('./components/ped-onpremise/Dashboardoffdock.vue').default },
    { path: '/dashboard-offdock-onpremise', component: require('./components/ped-onpremise/Dashboardoffdock.vue').default },
    { path: '/images-gallery', component: require('./components/ped-onpremise/Dashboard-images.vue').default },

    { path: '/docs', component: require('./components/Docs.vue').default },












    // { path: '*', component: require('./components/NotFound.vue').default },
    { path: '*', component: require('./components/Pedcapture.vue').default }
];
