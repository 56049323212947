<template>
  <div class="row ml-2">
    <div class="col-md-6">

      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Picture Evidence Display (Scrap & Overtime)</h3>
        </div>

<!--{{form.driver_face}}-->
        <form>
          <div class="card-body">
            <div class="form-group">
              <label class="required-field" for="">Chassis / ID</label>
              <input
                  type="text"
                  name="chassis"
                  v-model="form.chassis"
                  class="form-control"
                  id=""
                  placeholder="Enter Chassis Number or ID">
            </div>

            <div class="form-group">
              <label class="required-field" for="">Front View</label>
              <div class="input-group">
                <div class="custom-file">
                  <input
                      type="file"
                      class="custom-file-input"

                      name="front_view"
                      @change="updateFrontView"
                      accept="image/*" capture>


                  <label class="custom-file-label" for="">{{form.front_view ? "front_view.jpg": "Capture New"}}</label>
                </div>
                <div class="input-group-append">
                  <span class="input-group-text"> <i class="nav-icon fas fa-camera white"></i></span>

                </div>
              </div>

<!--              <input name="driver_face" type="file" @change="updateDriverFace" accept="image/*" capture class="form-input">-->
            </div>

            <div class="form-group">
              <label for="">Rear View</label>
              <div class="input-group">
                <div class="custom-file">
                  <input type="file"
                         class="custom-file-input"
                         name="rear_view"
                         accept="image/*" capture @change="updateRearView"


                         id="">
                  <label class="custom-file-label" for="">{{form.rear_view ? "rear_view.jpg":"Capture New"}}</label>
                </div>
                <div class="input-group-append">
                  <span class="input-group-text"> <i class="nav-icon fas fa-camera white"></i></span>

                </div>
              </div>

            </div>

            <div class="form-group">
              <label for="exampleInputFile">Other View</label>
              <div class="input-group">
                <div class="custom-file">
                  <input type="file" @change="updateOtherView"

                         class="custom-file-input" accept="image/*" capture id="">
                  <label class="custom-file-label" for="">{{ form.other_view ? "other_view.jpg":"Capture New" }}</label>
                </div>
                <div class="input-group-append">
                  <span class="input-group-text"> <i class="nav-icon fas fa-camera white"></i></span>

                </div>
              </div>
            </div>

            <div class="form-group">
              <label>Status</label>
              <select
                  name="activity"
                  v-model="form.status"
                  id="status"
                  class="form-control"
                  :class="{ 'is-invalid': form.errors.has('status') }"
              >
                <option value="">Select Status</option>
                <option value="Overtime">Overtime</option>
                <option value="Scrap">Scrap</option>
              </select>
              <has-error :form="form" field="status"></has-error>
            </div>

            <div class="form-group">
              <label class="required-field">Location</label>
              <select
                  name="location"

                  v-model="form.location" @change="loadDropdownB"
                  id="location"
                  class="form-control"
                  :class="{ 'is-invalid': form.errors.has('location') }"
              >
                <option value="">Select Location</option>
                <option value="Carpack C">Carpack C</option>
                <option value="Park Extension">Park Extension</option>
              </select>
              <has-error :form="form" field="location"></has-error>
            </div>

            <div class="form-group">
              <label>Section</label>
              <select
                  name="section"

                  v-model="form.section"
                  id="section"
                  class="form-control"
                  :class="{ 'is-invalid': form.errors.has('section') }"
              >
                <option value="">Select Section</option>
                <option v-for="option in dropdownOptionsB" :value="option.value" :key="option.value">
                  {{ option.label }}
                </option>
              </select>
              <has-error :form="form" field="section"></has-error>
            </div>



          </div>

          <div class="card-footer">
<!--            :disabled="( form.front_view == '' || form.other_view == '' || form.chassis == '') "-->
            <button  @click.prevent="submitped" class="btn btn-primary">Submit</button>
            <button  @click.prevent="clear" class="btn btn-primary">Reset</button>

          </div>


        </form>
      </div>



    </div>

  </div>
</template>

<script>
export default {
  mounted() {
    console.log("Component mounted.");
  },

  data() {
    return {
      selectedOptionA: null,
      selectedOptionB: null,
      // Define options for Dropdown B
      dropdownOptionsB: [],

      form: new Form({
        chassis : "",
        front_view:"",
        rear_view:"",
        other_view:'',
        status:"",
        location:"",
        section:"",

             }),
    };
  },

  methods: {
  
    loadDropdownB() {
      // Logic to load Dropdown B options based on selected value from Dropdown A
      if (this.form.location === 'Carpack C') {
        this.dropdownOptionsB = [
          { value: 'Orange', label: 'Orange' },
          { value: 'Blue', label: 'Blue' },
        ];
      } else if (this.form.location === 'Park Extension') {
        this.dropdownOptionsB = [
          { value: 'Red', label: 'Red' },
          { value: 'African Circle', label: 'African Circle' },
        ];
      } else {
        // If none selected or another condition, clear Dropdown B options
        this.dropdownOptionsB = [];
      }
      // Clear selected value in Dropdown B
      this.selectedOptionB = null;
    },
    clear() {

      this.form.chassis = "";
      this.form.front_view = "";
      this.form.rear_view = "";
      this.form.other_view = "";
      this.form.status = "";
      this.form.location = "";
      this.form.section = "";

    },

    submitped(){
      // let config = { headers: { 'Content-Type': 'multipart/form-data' } }
      this.$Progress.start();
           this.form.post("api/scrap-overtime")
          .then((response)=>{

            Toast.fire({
              icon: "success",
              title: response.data.message,
            });

           this.clear();
            this.$Progress.finish();
          }).catch(()=>{
             Toast.fire({
               icon: "success",
               title: 'Error Occur PED not Saved',
             });
             this.$Progress.fail();
           });


    },

    handleFileUpload(event, propertyName) {
      let file = event.target.files[0];
      let reader = new FileReader();

      reader.onloadend = () => {
        console.log('RESULT', reader.result);
        this.form[propertyName] = reader.result;
      };

      reader.readAsDataURL(file);
    },
    updateFrontView(e) {
      this.handleFileUpload(e, 'front_view');
    },

    updateRearView(e) {
      // this.handleFileUpload(e, 'dashboard');
      this.handleFileUpload(e, 'rear_view');
    },
    // updateKeys(e) {
    //   this.handleFileUpload(e, 'keys');
    // },

    updateOtherView(e){
      this.handleFileUpload(e, "other_view")
    },


  },
  created() {

  }
};
</script>

  <style scoped>
  .required-field::after {
    content: " *";
    color: red;
  }
  </style>
