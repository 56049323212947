<!-- Vue.js component template -->
<template>
  <div>
    <form @submit.prevent="uploadImage">
      <input type="file" ref="fileInput" @change="handleImageUpload" />
      <button type="submit">Upload Image</button>
    </form>
  </div>
</template>
 <script>
 // Vue.js component script
 export default {
 data() {
 return {
 selectedImage: null,
 };
 },
 methods: {
 handleImageUpload(event) {
 this.selectedImage = event.target.files[0];
 },
 async uploadImage() {
 const formData = new FormData();
 formData.append('image', this.selectedImage);

 try {
 const response = await axios.post('/api/upload', formData, {
 headers: {
 'Content-Type': 'multipart/form-data',
 },
 });
 console.log('Image uploaded successfully:', response.data);
 } catch (error) {
 console.error('Image upload failed:', error);
 }
 },
 },
 };

 </script>