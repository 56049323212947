<template>
  <div>
<!--    <div class="custom-bg sticky" style="z-index: 1">-->
<!--      <div class="d-flx row">-->
<!--        <div class="row ml-2">-->
<!--          <h2 class="pt-2 ml-2">PED</h2>-->
<!--        </div>-->

<!--      </div>-->
<!--    </div>-->
    <section class="content mt-3">

      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
<!--            <div class="card" v-if="$gate.isAdmin()">-->
              <div class="card" >
              <div class="card-header">
           <h3 class="card-title">PED Dashboard (Container)</h3>

<!--                <div class="card-tools">-->
<!--                  <button type="button" class="btn btn-sm btn-primary" @click="tozip">-->
<!--                    <i class="fa fa-download"></i>-->
<!--                    Download-->
<!--                  </button>-->
<!--                </div>-->



              </div>

              <!-- /.card-header -->
              <div  class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>Ped #</th>
                    <th data-toggle="tooltip" title="Container Number" class="truncate-text" >Container Number</th>
                    <th>Damage Pic 1</th>
                    <th>Damage Pic 2</th>
                    <th>Damage Pic 3</th>
                    <th>Damage Pic 4</th>
                    <th>Activity</th>
                    <th data-toggle="tooltip" title="Container Movement" class="truncate-text">Container Movement</th>
                    <th data-toggle="tooltip" title="Barging Activity" class="truncate-text">Barging Activity</th>
                    <th>Remarks</th>
                    <th>Captured By</th>


<!--                    <th>Action</th>-->
                  </tr>
                  </thead>
                  <tbody >
                  <tr @click="viewModal(ped)" v-for="ped in peds.data" :key="ped.id">
                    <td>{{ ped.id}}</td>

                    <td   class="text-capitalize">{{ ped.container_number }}</td>
                    <td class="text-capitalize">
                      <img :src="getDamagePic1(ped)" height="50" width="50">
                    </td>
                    <td class="text-capitalize">

                                            <img :src="getDamagePic2(ped)" height="50" width="50">

                    </td>

                    <td class="text-capitalize">

                      <img :src="getDamagePic3(ped)" height="50" width="50">

                    </td>

                    <td class="text-capitalize">

                      <img :src="getDamagePic4(ped)" height="50" width="50">

                    </td>
                    <td   class="text-capitalize">{{ ped.activity }}</td>
                    <td   class="text-capitalize">{{ ped.container_movement }}</td>
                    <td   class="text-capitalize">{{ ped.barging_activity }}</td>
                    <td   class="text-capitalize">{{ ped.remarks }}</td>
                    <td   class="">{{ ped.user }}</td>



<!--                    <td>-->
<!--                      <a href="#" @click="viewModal(ped)">-->
<!--                        <i class="fa fa-eye blue"></i>-->
<!--                      </a>-->
<!--                      /-->
<!--                      <a href="#" @click="deleteVendor(vendor.id)">-->
<!--                        <i class="fa fa-trash red"></i>-->
<!--                      </a>-->
<!--                    </td>-->

<!--                    BIG MODAL-->



                    </tr>

                  </tbody>
                </table>
              </div>

              <div class="card-footer">
                <pagination
                    :data="peds"
                    @pagination-change-page="getResults"
                ></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

<!--        <div v-if="!$gate.isAdmin()">-->
<!--          <not-found></not-found>-->
<!--        </div>-->
        <div id="divToPrint">
          <div v-if="selectedPed"
               id="viewNew" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="container">
                  <div class="row p-4">
                    <div class=""><img class="modal-image" src="http://eservices.fsl.nalcometgroup.com/_nuxt/img/logo.0912d5b.png" alt=""></div>
                    <h3 class="mt-4 ml-5">PICTURE EVIDENCE DISPLAY</h3>
                    <!--DATA TABLE-->

                    <table class="table table-borderless">
                      <thead>
                      <tr>
                        <th scope="col">Container Number: {{selectedPed.container_number}}</th>
                        <th scope="col">Date Captured: {{selectedPed.created_at | myDate }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      </tbody>
                    </table>

                    <div class="scrollable-div">
                      <table class="table table-image">
                        <thead>
                        <tr>

                          <th scope="col">Damage Pic 1</th>
                          <th scope="col">Damage Pic 1</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr>

                          <td class="w-25">

                            <img :src="'images/pedcontainer/' + selectedPed.damage_pic1" height="300" width="300">
<!--                                 @mouseover="startMagnify"-->
<!--                                 @mousemove="magnify"-->
<!--                                 @mouseout="stopMagnify"-->
<!--                                 :style="{ transform: 'scale(' + zoom + ')' }"-->
                          </td>

                          <td class="w-25">
                            <img :src="'images/pedcontainer/' + selectedPed.damage_pic2" height="300" width="300">
                          </td>
                        </tr>

                        </tbody>
                      </table>

                      <table class="table table-image">
                        <thead>
                        <tr>

                          <th scope="col">Damage Pic 3</th>
                          <th scope="col">amage Pic 4</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr>

                          <td class="w-25">
                            <img :src="'images/pedcontainer/' + selectedPed.damage_pic3" height="300" width="300">
                          </td>

                          <td class="w-25">
                            <img :src="'images/pedcontainer/' + selectedPed.damage_pic4" height="300" width="300">
                          </td>
                        </tr>

                        </tbody>
                      </table>




                    </div>


                  </div>
                  <div class="row">
                    <div class="col-md-12 d-flex justify-content-center align-items-center">
                    <div class="mb-3">
                      <button @click="printDiv">Print</button>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        </div>

      </div>
  </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {

    return {
      selectedPed: null,
      zoom: 1,
      search: "",
      masks: {
        input: "DD-MM-YYYY",
      },
      fie: "",
      editmode: false,
      peds: {},
      form: new Form({
        id: "",
        chassis: "",
        driver_face: "",
        keys:""


      }),
    };
  },
  methods: {
    res1(){ console.log('I work')},
    tozip() {
      // Send an AJAX request to your Laravel route to initiate the download
      axios.get('api/download-zip-peds')
          .then((data) => {
            if (message === 'OK') {
              // return response.blob();
              this.res1();
            }
          });
      // window.location.href = '/images/pedcontainer/zip/converted_files.zip';
      // Define the file path
      // const filePath = '/images/pedcontainer/zip/converted_files.zip'; // Replace with the actual file path
      //
      // // Create an anchor element for the download
      // const downloadLink = document.createElement('a');
      // downloadLink.href = filePath;
      // downloadLink.target = '_blank';
      // downloadLink.download = 'converted_files.zip'; // Specify the desired filename
      //
      // // Trigger a click event on the anchor to initiate the download
      // downloadLink.click();
    },


    tozip1(){
      this.$Progress.start();

      axios
          .get("api/download-zip-peds");
          // .then(({ data }) => (this.peds = data.data));

      this.$Progress.finish();
    },

    getResults(page = 1) {
      this.$Progress.start();

      axios
          .get("api/pedscontainer?page=" + page)
          .then(({ data }) => (this.peds = data.data));

      this.$Progress.finish();
    },

    startMagnify() {
      this.zoom = 2; // Adjust the zoom level as needed
    },
    magnify(event) {
      const element = event.target;
      const { width, height } = element.getBoundingClientRect();
      const { clientX, clientY } = event;

      const x = (clientX - element.offsetLeft) / width;
      const y = (clientY - element.offsetTop) / height;

      element.style.transformOrigin = `${x * 100}% ${y * 100}%`;
    },
    stopMagnify() {
      this.zoom = 1;
    },

    printDiv() {
      const divToPrint = document.getElementById("divToPrint");
      const newWin = window.open("", "_blank");
      newWin.document.open();
      newWin.document.write('<html><head><title>Print</title></head><body>');
      newWin.document.write('<div>' + divToPrint.innerHTML + '</div>');
      newWin.document.write('</body></html>');
      newWin.document.close();
      newWin.print();
      newWin.close();
    },


    viewModal(ped) {
      // this.editmode = false;
      // this.form.reset();
      this.selectedPed = ped;
      $("#viewNew").modal("show");


    },

    newLargeModal() {
      // this.editmode = false;
      // this.form.reset();
      $("#addNew").modal("show");
    },

    editModal(ped) {
      // // this.editmode = true;
      // this.form.reset();
      // $("#addNew").modal("show");
      // this.form.fill(ped);
    },

    loadAll() {
      this.$Progress.start();

      // if(this.$gate.isAdmin()){
      axios.get("api/pedscontainer").then(({ data }) => (this.peds = data.data));
      //console.log(vendors);
      // }

      this.$Progress.finish();
    },

    getDamagePic1(ped){
         return "images/pedcontainer/"+ ped.damage_pic1;
    },
    getDamagePic2(ped){
      return "images/pedcontainer/"+ ped.damage_pic2;
    },
    getDamagePic3(ped){
      return "images/pedcontainer/"+ ped.damage_pic3;
    },
    //
    getDamagePic4(ped){
      return "images/pedcontainer/"+ ped.damage_pic4;
    },
    //

    // getImageAll(ped, imageType) {
    //   const imageBasePath = "images/ped/";
    //
    //   switch (imageType) {
    //
    //     case "dashboard":
    //       return imageBasePath + ped.dashboard;
    //     case "keys":
    //       return imageBasePath + ped.keys;
    //     case "frontview":
    //       return imageBasePath + ped.frontview;
    //     case "vehicle_back_view":
    //       return imageBasePath + ped.frontview
    //     case "chassis_capture":
    //       return imageBasePath + this.peds.backview;
    //     case "driver_face":
    //       return imageBasePath + ped.driver_face;
    //       // Add more cases for other image types as needed
    //     default:
    //       return ""; // Return a default value or handle error cases
    //   }
    // },

    // getDriverface(ped){
    //
    //   const driverFaceImagePath = getImage(ped, this.form.driver_face);
    //   return driverFaceImagePath;
    //   // this.getImageAll(ped, this.form.driver_face);
    // },




    searchit() {
      let query = this.search;
      //console.log(query);
      axios.get("api/findAsset?q=" + query).then((data) => {
        this.assets = data.data;
      });
    },
  },

  created() {
    //this.searchit();


    Fire.$on('searching', ()=>{
      let query = this.$parent.search;
      axios.get('api/findPeds-container?q=' + query)
          .then( (data)=>{
            this.peds = data.data;
          }).catch();
    })

    this.$Progress.start();
    this.loadAll();
    this.viewModal();
    this.$Progress.finish();
  },
};
</script>

<style scoped>

.truncate-text {
  max-width: 150px; /* Adjust the maximum width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.modal-image{
  width: 150px;
  height: 80px;
}
.form-control {
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 1.1rem;
}
.fai {
  margin-top: 12px;
  cursor: pointer;
  color: blue;
}

.fai:hover {
  color: rgb(97, 97, 176);
}
.d-flx {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.custom-bg {
  background-color: #e8f9fc !important;
  height: 80px;
  border-top: 1px solid #e0eaee;
  border-bottom: 1px solid #e0eaee;
  padding: 10px 20px;
  width: 100%;
}


.scrollable-div {
  /* Set a fixed height for the div */
  height: 500px; /* Adjust the height to your preference */

  /* Enable vertical scrolling when the content overflows the fixed height */
  overflow-y: scroll;

  /* Optional: Add padding or other styles to the div */
  padding: 10px;
  border: 1px solid #ccc;
}

div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.btn {
  height: 40px;
}
</style>