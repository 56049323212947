<template>
  <div>
<!--    <div class="custom-bg sticky" style="z-index: 1">-->
<!--      <div class="d-flx row">-->
<!--        <div class="row ml-2">-->
<!--          <h2 class="pt-2 ml-2">PED</h2>-->
<!--        </div>-->

<!--      </div>-->
<!--    </div>-->
    <section class="content mt-3">

      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
<!--            <div class="card" v-if="$gate.isAdmin()">-->
              <div class="card">
              <div class="card-header">
           <h3 class="card-title">PED Dashboard On Premise (On Board)</h3>

<!--                <div class="card-tools">-->
<!--                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">-->
<!--                    <i class="fa fa-plus-square"></i>-->
<!--                    Add New-->
<!--                  </button>-->
<!--                </div>-->



              </div>

              <!-- /.card-header -->
              <div  class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>Ped #</th>
                    <th>Chassis</th>
                    <th>Driver Face</th>
                    <th>Dashboard</th>
                    <th>Keys</th>
                    <th>Front View</th>
                    <th>Back View</th>
                    <th>Chassis View</th>
                    <th>Captured By</th>


<!--                    <th>Action</th>-->
                  </tr>
                  </thead>
                  <tbody   >
                  <tr @click="viewModal(ped)" v-for="ped in peds.data" :key="ped.id">

                    <td>{{ ped.id}}</td>

                    <td   class="text-capitalize">{{ ped.chassis }}</td>
                    <td class="text-capitalize">
                      <img :src="getDriver(ped)" height="50" width="50">
                    </td>
                    <td class="text-capitalize">

                                            <img :src="getDashboard(ped)" height="50" width="50">

                    </td>

                    <td class="text-capitalize">

                      <img :src="getVehicle_Keys(ped)" height="50" width="50">

                    </td>

                    <td class="text-capitalize">

                      <img :src="getFront(ped)" height="50" width="50">

                    </td>

                    <td class="text-capitalize">
                      <!--                      <img :src="getImage(ped)" height="100" width="100">-->
                      <img :src="getBack(ped)" height="50" width="50">

                    </td>

                    <td class="text-capitalize">
                      <!--                      <img :src="getImage(ped)" height="100" width="100">-->
                      <img :src="getChassis_capture(ped)" height="50" width="50">

                    </td>
                    <td   class="">{{ ped.user }}</td>
<!--                    <td>-->
<!--                      <a href="#" @click="viewModal(ped)">-->
<!--                        <i class="fa fa-eye blue"></i>-->
<!--                      </a>-->
<!--                      /-->
<!--                      <a href="#" @click="deleteVendor(vendor.id)">-->
<!--                        <i class="fa fa-trash red"></i>-->
<!--                      </a>-->
<!--                    </td>-->

<!--                    BIG MODAL-->

                    </tr>

                  </tbody>
                  <tbody  >
<!--                  <tr v-show="peds.lenght < 0">-->
<!--                    <p>No data</p>-->
<!--                  </tr>-->

                  </tbody>
                </table>
              </div>

<!--              <div class="card-footer">-->
<!--                <pagination-->
<!--                    :data="peds"-->
<!--                    @pagination-change-page="getResults"-->
<!--                ></pagination>-->
<!--              </div>-->


            </div>
            <!-- /.card -->
          </div>
        </div>

<!--        <div v-if="!$gate.isAdmin()">-->
<!--          <not-found></not-found>-->
<!--        </div>-->
        <div id="divToPrint">
          <div v-if="selectedPed"
               id="viewNew" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="container">
                  <div class="row p-4">
                    <div class=""><img class="modal-image" src="http://eservices.fsl.nalcometgroup.com/_nuxt/img/logo.0912d5b.png" alt=""></div>
                    <h3 class="mt-4 ml-5">PICTURE EVIDENCE DISPLAY</h3>
                    <!--DATA TABLE-->

                    <table class="table table-borderless">
                      <thead>
                      <tr>
                        <th scope="col">Chassis Number: {{selectedPed.chassis}}</th>
                        <th scope="col">Date Captured: {{selectedPed.created_at | myDate }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      </tbody>
                    </table>

                    <div class="scrollable-div">
                      <table class="table table-image">
                        <thead>
                        <tr>

                          <th scope="col">Driver Face</th>
                          <th scope="col">Dashboard</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr>

                          <td class="w-25">

                            <img :src="'https://app.ped.nalcometgroup.net/images/ped/' + selectedPed.driver_face" height="300" width="300">
<!--                                 @mouseover="startMagnify"-->
<!--                                 @mousemove="magnify"-->
<!--                                 @mouseout="stopMagnify"-->
<!--                                 :style="{ transform: 'scale(' + zoom + ')' }"-->
                          </td>

                          <td class="w-25">
                            <img :src="'https://app.ped.nalcometgroup.net/images/ped/' + selectedPed.dashboard" height="300" width="300">
                          </td>
                        </tr>

                        </tbody>
                      </table>

                      <table class="table table-image">
                        <thead>
                        <tr>

                          <th scope="col">Vehicle Front View</th>
                          <th scope="col">Vehicle Back View</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr>

                          <td class="w-25">
                            <img :src="'https://app.ped.nalcometgroup.net/images/ped/' + selectedPed.vehicle_front_view" height="300" width="300">
                          </td>

                          <td class="w-25">
                            <img :src="'https://app.ped.nalcometgroup.net/images/ped/' + selectedPed.vehicle_back_view" height="300" width="300">
                          </td>
                        </tr>

                        </tbody>
                      </table>

                      <table class="table table-image">
                        <thead>
                        <tr>

                          <th scope="col">Vehicle Chassis</th>
                          <th scope="col">Vehicle Key</th>

                        </tr>
                        </thead>

                        <tbody>
                        <tr>

                          <td class="w-25">
                            <img :src="'https://app.ped.nalcometgroup.net/images/ped/' + selectedPed.chassis_capture" height="300" width="300">
                          </td>
                          <td class="w-25">
                            <img :src="'https://app.ped.nalcometgroup.net/images/ped/' + selectedPed.keys" height="300" width="300">
                          </td>


                        </tr>

                        </tbody>
                      </table>


                    </div>


                  </div>
                  <div class="row">
                    <div class="col-md-12 d-flex justify-content-center align-items-center">
                    <div class="mb-3">
                      <button @click="printDiv">Print</button>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        </div>

      </div>
  </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {

    return {
      selectedPed: null,
      zoom: 1,
      search: "",
      masks: {
        input: "DD-MM-YYYY",

      },
      fie: "",
      editmode: false,
      peds: {},
      form: new Form({
        id: "",
        chassis: "",
        driver_face: "",
        keys:""


      }),
    };
  },
  methods: {

    // getResults(page = 1) {
    //   this.$Progress.start();
    //
    //   axios
    //       .get("api/peds?page=" + page)
    //       .then(({ data }) => (this.peds = data.data));
    //
    //   this.$Progress.finish();
    // },

    startMagnify() {
      this.zoom = 2; // Adjust the zoom level as needed
    },
    magnify(event) {
      const element = event.target;
      const { width, height } = element.getBoundingClientRect();
      const { clientX, clientY } = event;

      const x = (clientX - element.offsetLeft) / width;
      const y = (clientY - element.offsetTop) / height;

      element.style.transformOrigin = `${x * 100}% ${y * 100}%`;
    },
    stopMagnify() {
      this.zoom = 1;
    },

    printDiv() {
      const divToPrint = document.getElementById("divToPrint");
      const newWin = window.open("", "_blank");
      newWin.document.open();
      newWin.document.write('<html><head><title>Print</title></head><body>');
      newWin.document.write('<div>' + divToPrint.innerHTML + '</div>');
      newWin.document.write('</body></html>');
      newWin.document.close();
      newWin.print();
      newWin.close();
    },


    viewModal(ped) {
      // this.editmode = false;
      // this.form.reset();
      this.selectedPed = ped;
      $("#viewNew").modal("show");


    },

    newLargeModal() {
      // this.editmode = false;
      // this.form.reset();
      $("#addNew").modal("show");
    },

    editModal(ped) {
      // // this.editmode = true;
      // this.form.reset();
      // $("#addNew").modal("show");
      // this.form.fill(ped);
    },



    loadAll() {
      this.$Progress.start();

      // if(this.$gate.isAdmin()){
      axios.get("api/peds").then(({ data }) => (this.peds = data.data));
      //console.log(vendors);
      // }

      this.$Progress.finish();
    },

    getDriver(ped){
      // return "images/ped/"+ ped.driver_face;
      return "https://app.ped.nalcometgroup.net/images/ped/"+ ped.driver_face;

    },

    // https://app.ped.nalcometgroup.net/images/ped/074231_driver_face_1703232182.jpeg
    getDashboard(ped){
      return "https://app.ped.nalcometgroup.net/images/ped/"+ ped.dashboard;
    },
    getVehicle_Keys(ped){
      return "https://app.ped.nalcometgroup.net/images/ped/"+ ped.keys;
    },
    //
    getFront(ped){
      return "https://app.ped.nalcometgroup.net/images/ped/"+ ped.vehicle_front_view;
    },
    getBack(ped){
      return "https://app.ped.nalcometgroup.net/images/ped/"+ ped.vehicle_back_view;
    },
    //
    getChassis_capture(ped){
      return "https://app.ped.nalcometgroup.net/images/ped/"+ ped.chassis_capture;
    },
    // getImageAll(ped, imageType) {
    //   const imageBasePath = "images/ped/";
    //
    //   switch (imageType) {
    //
    //     case "dashboard":
    //       return imageBasePath + ped.dashboard;
    //     case "keys":
    //       return imageBasePath + ped.keys;
    //     case "frontview":
    //       return imageBasePath + ped.frontview;
    //     case "vehicle_back_view":
    //       return imageBasePath + ped.frontview
    //     case "chassis_capture":
    //       return imageBasePath + this.peds.backview;
    //     case "driver_face":
    //       return imageBasePath + ped.driver_face;
    //       // Add more cases for other image types as needed
    //     default:
    //       return ""; // Return a default value or handle error cases
    //   }
    // },

    // getDriverface(ped){
    //
    //   const driverFaceImagePath = getImage(ped, this.form.driver_face);
    //   return driverFaceImagePath;
    //   // this.getImageAll(ped, this.form.driver_face);
    // },




    searchit() {
      let query = this.search;
      //console.log(query);
      axios.get("api/findAsset?q=" + query).then((data) => {
        this.assets = data.data;
      });
    },
  },

  created() {
    //this.searchit();


    Fire.$on('searching', ()=>{
      let query = this.$parent.search;
      axios.get('api/findPeds?q=' + query)
          .then( (data)=>{
            this.peds = data.data;
          }).catch();
    })

    this.$Progress.start();
    this.loadAll();

    this.viewModal();
    this.$Progress.finish();
  },
};
</script>

<style scoped>

.modal-image{
  width: 150px;
  height: 80px;
}
.form-control {
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 1.1rem;
}
.fai {
  margin-top: 12px;
  cursor: pointer;
  color: blue;
}

.fai:hover {
  color: rgb(97, 97, 176);
}
.d-flx {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.custom-bg {
  background-color: #e8f9fc !important;
  height: 80px;
  border-top: 1px solid #e0eaee;
  border-bottom: 1px solid #e0eaee;
  padding: 10px 20px;
  width: 100%;
}


.scrollable-div {
  /* Set a fixed height for the div */
  height: 500px; /* Adjust the height to your preference */

  /* Enable vertical scrolling when the content overflows the fixed height */
  overflow-y: scroll;

  /* Optional: Add padding or other styles to the div */
  padding: 10px;
  border: 1px solid #ccc;
}

div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.btn {
  height: 40px;
}
</style>